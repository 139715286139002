<template>
  <b-card>
    <b-card-body>

      <!-- Swiper -->
      <swiper
        class="swiper-responsive-breakpoints px-4 py-2"
        :options="swiperOptions"
      >
        <swiper-slide>
          <b-link @click="changeSelectedPicture('tag')">
            <div class="img-container w-100 h-100 mx-auto py-0">
              <b-img
                :src="require(`@/assets/images/cards/${selectedColor}.png`)"
                fluid
              />
            </div>
          </b-link>
        </swiper-slide>
        <swiper-slide>
          <b-link @click="changeSelectedPicture('phone')">
            <div class="img-container w-100 h-100 mx-auto py-0">
              <b-img
                :src="require(`@/assets/images/cards/${selectedColor}.png`)"
                fluid
              />
            </div>
          </b-link>
        </swiper-slide>

        <!-- Add Arrows -->
        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
      </swiper>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BImg, BLink,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BCard,
    BCardBody,
    BImg,
    BLink,

    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters({
      selectedColor: 'eCommerce/getPhoneCardColorType',
    }),
  },
  methods: {
    ...mapActions({
      changeSelectedPicture: 'eCommerce/changePhoneCardSelectedPicture',
    }),
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 3,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
