<template>
  <phone-card-detail />
</template>

<script>
import PhoneCardDetail from './apps/e-commerce/e-commerce-product-details/ECommercePhoneCardDetails.vue'

export default {
  components: {
    PhoneCardDetail,
  },
}
</script>

<style>

</style>
